
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "EventModeration",
  components: { Pagination },
  setup() {
    const store = useStore();

    let currentPageURL = ref("");

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT_MODERATION + "getIsLoading"];
    });
    let events = computed(() => {
      return store.getters[Modules.EVENT_MODERATION + "getEvents"];
    });
    let eventsMeta = computed(() => {
      return store.getters[Modules.EVENT_MODERATION + "getMeta"];
    });

    onMounted(() => {
      setCurrentPageTitle("Event Moderation");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(
        Modules.EVENT_MODERATION + Actions.FETCH_EVENTS_FOR_MODERATION,
        {
          // search: search.value,
          // visibility: visibility.value,
          url: currentPageURL.value,
        }
      );
    }

    const approve = (id) => {
      store
        .dispatch(
          Modules.EVENT_MODERATION + Actions.APPROVE_EVENT_FOR_MODERATION,
          id
        )
        .then(() => {
          Swal.fire({
            text: store.getters[Modules.EVENT_MODERATION + "getSuccessMessage"],
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            store.dispatch(
              Modules.EVENT_MODERATION + Actions.FETCH_EVENTS_FOR_MODERATION
            );
          });
        })
        .catch(() => {
          const [error] = Object.keys(
            store.getters[Modules.EVENT_MODERATION + "getErrors"]
          );
          Swal.fire({
            text: store.getters[Modules.EVENT_MODERATION + "getErrors"][error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };
    const reject = (id) => {
      store
        .dispatch(
          Modules.EVENT_MODERATION + Actions.REJECT_EVENT_FOR_MODERATION,
          id
        )
        .then(() => {
          Swal.fire({
            text: store.getters[Modules.EVENT_MODERATION + "getSuccessMessage"],
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            store.dispatch(
              Modules.EVENT_MODERATION + Actions.FETCH_EVENTS_FOR_MODERATION
            );
          });
        })
        .catch(() => {
          const [error] = Object.keys(
            store.getters[Modules.EVENT_MODERATION + "getErrors"]
          );
          Swal.fire({
            text: store.getters[Modules.EVENT_MODERATION + "getErrors"][error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      formatDateTime,
      isLoading,
      events,
      eventsMeta,
      approve,
      reject,

      refresh,
      gotoPage,
    };
  },
});
